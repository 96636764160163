import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import NotificationToast from "../../components/Helpers/NotificationToast";
import * as Yup from "yup";
import { Tab } from "@headlessui/react";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import SettingsSelect from "../Forms/SettingsSelect";
import SettingsTextInput from "../Forms/SettingsTextInput";
import SubmitButton from "../Forms/SubmitButton";
import AppLoader from "../Helpers/AppLoader";
import PhoneInput from "react-phone-number-input";

import { faBank } from "@fortawesome/pro-regular-svg-icons";
import FileUpload from "../Forms/FileUpload";
import FileDisplaySimple from "../Forms/FileDisplaySimple";
import CustomButton from "../Helpers/CustomButton";

const subNavigation = [{ name: " - Banking Details", icon: faBank }];
const jointSubNavigation = [{ name: " - Banking Details", icon: faBank }];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export default function ApplicationForm() {
    const [userID, setUserID] = useState("");
    const [showError, setShowError] = useState("");
    const [current_booking, setCurrentBooking] = useState({});
    const [bankList, setBankList] = useState([]);
    const [initialValues, setInitialValues] = useState({});
    const [fileUploadInformation, setFileUploadInformation] = useState([]);
    const [otpSent, setOtpSent] = useState(false);

    useEffect(async () => {
        if (bankList.length == 0) {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}paystack_banks`,
            })
                .then((response) => {
                    let banks = response.data.data;
                    let bankList = [];

                    Object.keys(banks).forEach((key) => {
                        bankList.push({ key: banks[key].name, value: banks[key].name, label: banks[key].name, code: banks[key].code });
                    });

                    setBankList(bankList);
                })
                .catch((error) => {
                    alert("Bank failed");
                });
        }
    });

    return (
        <div>
            <div className="pb-32 elative bg-fixed bg-bottom bg-cover bg-no-repeat application_form">
                <header className="max-w-7xl mx-auto py-10 px-2 lg:px-0">
                    <div className="lg:flex">
                        <img className="h-24" src="/images/logo.svg" alt="Quorum Living" />
                        <div className="py-6 lg:py-0 mx-auto md:w-96 text-center">
                            <h1 className="text-3xl text-white">Welcome back to QLiving</h1>
                            <p className="text-gray-200 mt-4">
                                Update your information at each <span className="italic font-bold">Section</span> and update your application by clicking{" "}
                                <span className="italic font-bold">Submit Application</span>.
                            </p>
                        </div>
                    </div>
                </header>
            </div>

            <main className="-mt-32">
                <div>
                    <div style={{ borderTopRightRadius: "50px" }} className="bg-white min-h-screen shadow-md rounded-tl-3xl sm:-ml-52 sm:mr-52 sm:pl-52">
                        <div className="h-20 flex">
                            <div className="flex-1 grow bg-primary z-50 flex items-center justify-between px-11" style={{ borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}>
                                <div className="text-white text-2xl font-bold uppercase">Please update your banking details</div>
                            </div>
                            <div className="w-1/5 colour-uc5 z-10" style={{ marginLeft: "-40px", borderTopRightRadius: "50px", borderBottomRightRadius: "50px" }}></div>
                        </div>

                        {!current_booking.id ? (
                            <div>
                                {otpSent === false ? (
                                    <Formik
                                        initialValues={{ id_number: "", cell: "" }}
                                        enableReinitialize="true"
                                        validateOnChange={true}
                                        validateOnBlur={true}
                                        validationSchema={Yup.object().shape({
                                            id_number: Yup.string().required("id_number"),
                                            cell: Yup.string().required("cell"),
                                        })}
                                        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                            setSubmitting(true);

                                            await axios({
                                                method: "post",
                                                url: `${process.env.REACT_APP_API_URL}update_details_otp`,
                                                data: values,
                                            })
                                                .then((response) => {
                                                    console.log(response);

                                                    if (response.data == "error") {
                                                        setShowError("We cannot find your details. Please make sure you have entered the correct details");
                                                    } else if (response.data > 0) {
                                                        setOtpSent(true);
                                                        setUserID(response.data);
                                                        setShowError("");
                                                    } else {
                                                        setShowError("Something went wrong. Please try again later.");
                                                    }
                                                })
                                                .catch((response) => {
                                                    toast(<NotificationToast title={"Something went wrong"} message={response.response.data.message} type="error" />);
                                                });
                                        }}
                                    >
                                        {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                                            <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4 mt-10">
                                                <div className="max-w-6xl mx-auto">
                                                    <Tab.Group>
                                                        <Tab.Panels className="my-6 xl:my-0 rounded-3xl bg-gray-100 xl:col-span-9 mx-auto">
                                                            <Tab.Panel>
                                                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                                    <p className="text-center font-bold">We need to verify your details first</p>
                                                                    <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6 uppercase">
                                                                        <Field name="id_number" type="text" as={SettingsTextInput} label="ID or Passport Number" required />

                                                                        <div className="col-span-2 sm:col-span-3">
                                                                            <label className="text-sm font-medium text-gray-700 flex items-baseline justify-between mb-2">Whatsapp Number</label>
                                                                            <PhoneInput
                                                                                defaultCountry="ZA"
                                                                                countryCallingCodeEditable={false}
                                                                                onChange={(val) => {
                                                                                    setFieldValue("cell", val);
                                                                                }}
                                                                                onBlur={setFieldTouched}
                                                                                name="cell"
                                                                                value={values.cell}
                                                                            />
                                                                            <p className="text-red-400 text-sm font-light">{errors.cell}</p>
                                                                        </div>
                                                                    </div>

                                                                    <br />
                                                                    <SubmitButton
                                                                        name="Update"
                                                                        disabled={!isValid || isSubmitting || !dirty}
                                                                        isSubmitting={isSubmitting}
                                                                        icon={faSave}
                                                                        text="Send OTP"
                                                                        style={{
                                                                            width: "100%",
                                                                        }}
                                                                    />

                                                                    {showError && <p className="text-red-500 text-sm font-ligh text-center mt-4 font-bold">{showError}</p>}
                                                                </div>
                                                            </Tab.Panel>
                                                        </Tab.Panels>
                                                    </Tab.Group>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                ) : (
                                    <Formik
                                        initialValues={{ otp: "", user_id: userID }}
                                        enableReinitialize="true"
                                        validateOnChange={true}
                                        validateOnBlur={true}
                                        validationSchema={Yup.object().shape({
                                            otp: Yup.string().required("otp"),
                                        })}
                                        onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                            setSubmitting(true);

                                            await axios({
                                                method: "post",
                                                url: `${process.env.REACT_APP_API_URL}check_otp`,
                                                data: values,
                                            })
                                                .then((response) => {
                                                    console.log(response);

                                                    if (response.data == "error") {
                                                        setShowError("Incorrect OTP. Please try again.");
                                                    } else if (response.data == "expired") {
                                                        setShowError("Your OTP has expired, we have sent a new one.");
                                                    } else if (response.data.id > 0) {
                                                        setCurrentBooking(response.data);

                                                        setInitialValues({
                                                            banking_bank: response.data?.user?.profile?.banking_bank ? response.data.user.profile.banking_bank : null,
                                                            banking_account_type: response.data?.user?.profile?.banking_account_type ? response.data.user.profile.banking_account_type : null,
                                                            banking_branch: response.data?.user?.profile?.banking_branch ? response.data.user.profile.banking_branch : null,
                                                            banking_branch_code: response.data?.user?.profile?.banking_branch_code ? response.data.user.profile.banking_branch_code : null,
                                                            banking_account_number: response.data?.user?.profile?.banking_account_number ? response.data.user.profile.banking_account_number : null,
                                                            banking_account_holder: response.data?.user?.profile?.banking_account_holder ? response.data.user.profile.banking_account_holder : null,

                                                            joint_application: response.data.joint_application,

                                                            joint_application_banking_bank: response.data?.user?.joint_application?.banking_bank ?? null,
                                                            joint_application_banking_account_type: response.data?.user?.joint_application?.banking_account_type ?? null,
                                                            joint_application_banking_branch: response.data?.user?.joint_application?.banking_branch ?? null,
                                                            joint_application_banking_branch_code: response.data?.user?.joint_application?.banking_branch_code ?? null,
                                                            joint_application_banking_account_number: response.data?.user?.joint_application?.banking_account_number ?? null,
                                                            joint_application_banking_account_holder: response.data?.user?.joint_application?.banking_account_holder ?? null,

                                                            edit_url: response.data?.url,
                                                        });
                                                    } else {
                                                        alert("Something went wrong. Please try again later.");
                                                    }
                                                })
                                                .catch((response) => {
                                                    toast(<NotificationToast title={"Something went wrong"} message={response.response.data.message} type="error" />);
                                                });
                                        }}
                                    >
                                        {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                                            <Form onSubmit={handleSubmit} className="space-y-8 divide-y divide-gray-200 m-4 mt-10">
                                                <div className="max-w-6xl mx-auto">
                                                    <Tab.Group>
                                                        <Tab.Panels className="my-6 xl:my-0 rounded-3xl bg-gray-100 xl:col-span-9 mx-auto">
                                                            <Tab.Panel>
                                                                <div className="py-6 px-4 sm:p-6 xl:pb-8">
                                                                    <div className="py-8 px-4 space-y-6 sm:space-y-0 xl:px-6 uppercase">
                                                                        <Field name="otp" type="text" as={SettingsTextInput} label="Please enter your OTP" required />
                                                                    </div>

                                                                    <br />
                                                                    <SubmitButton
                                                                        name="Update"
                                                                        disabled={!isValid || isSubmitting || !dirty}
                                                                        isSubmitting={isSubmitting}
                                                                        icon={faSave}
                                                                        text="Check OTP"
                                                                        style={{
                                                                            width: "100%",
                                                                        }}
                                                                    />

                                                                    {showError && <p className="text-red-500 text-sm font-ligh text-center mt-4 font-bold">{showError}</p>}
                                                                </div>
                                                            </Tab.Panel>
                                                        </Tab.Panels>
                                                    </Tab.Group>
                                                </div>
                                            </Form>
                                        )}
                                    </Formik>
                                )}
                            </div>
                        ) : (
                            <>
                                {current_booking &&
                                current_booking?.user?.name &&
                                current_booking?.user?.surname &&
                                current_booking?.user?.cell &&
                                current_booking?.user?.email &&
                                current_booking?.user?.profile?.id_number ? (
                                    <>
                                        <Formik
                                            initialValues={initialValues}
                                            enableReinitialize="true"
                                            validateOnChange={true}
                                            validateOnBlur={true}
                                            validationSchema={Yup.object().shape({
                                                banking_bank: Yup.string().nullable().required("name of the bank for Applicant One"),
                                                banking_account_type: Yup.string().nullable().required("type of account for Applicant One"),
                                                banking_branch: Yup.string().nullable().required("bank branch for Applicant One"),
                                                banking_branch_code: Yup.string().nullable().required("bank branch code for Applicant One"),
                                                banking_account_number: Yup.string().nullable().required("bank acount number for Applicant One"),
                                                banking_account_holder: Yup.string().nullable().required("banking account holder for Applicant One"),

                                                joint_application_banking_bank: Yup.string().when("joint_application", {
                                                    is: (value) => value && value == 1,
                                                    then: Yup.string().nullable().required("name of the bank for Applicant Two"),
                                                    otherwise: Yup.string().nullable(),
                                                }),
                                                joint_application_banking_account_type: Yup.string().when("joint_application", {
                                                    is: (value) => value && value == 1,
                                                    then: Yup.string().nullable().required("type of account for Applicant Two"),
                                                    otherwise: Yup.string().nullable(),
                                                }),
                                                joint_application_banking_branch_code: Yup.string().when("joint_application", {
                                                    is: (value) => value && value == 1,
                                                    then: Yup.string().nullable().required("bank branch code for Applicant Two"),
                                                    otherwise: Yup.string().nullable(),
                                                }),
                                                joint_application_banking_account_number: Yup.string().when("joint_application", {
                                                    is: (value) => value && value == 1,
                                                    then: Yup.string().nullable().required("bank account number for Applicant Two"),
                                                    otherwise: Yup.string().nullable(),
                                                }),
                                                joint_application_banking_account_holder: Yup.string().when("joint_application", {
                                                    is: (value) => value && value == 1,
                                                    then: Yup.string().nullable().required("banking account holder for Applicant Two"),
                                                    otherwise: Yup.string().nullable(),
                                                }),
                                            })}
                                            onSubmit={async (values, { setSubmitting, setErrors, resetForm }) => {
                                                setSubmitting(true);
                                                // adjust the financial information to floats
                                                // deep clone object to not adjust original values
                                                let data = JSON.parse(JSON.stringify(values));
                                                await Object.keys(bankList).forEach((key) => {
                                                    if (data.banking_bank == bankList[key].label) {
                                                        data.banking_bank_code = bankList[key].code;
                                                    }
                                                });
                                                if (data.joint_application == 1) {
                                                    await Object.keys(bankList).forEach((key) => {
                                                        if (data.joint_application_banking_bank == bankList[key].label) {
                                                            data.banking_bank_code_joint = bankList[key].code;
                                                        }
                                                    });
                                                }
                                                let api_method = "post";
                                                data.banking_updated = "1";
                                                await axios({
                                                    method: api_method,
                                                    url: `${process.env.REACT_APP_API_URL}user_banking_details`,
                                                    data: data,
                                                })
                                                    .then((response) => {
                                                        if (values.edit_url) {
                                                            toast(
                                                                <NotificationToast
                                                                    title={"Application Updated"}
                                                                    message="Your application has succesfully been updated. Keep an eye out on your email and cellphone for future communication"
                                                                />
                                                            );
                                                        } else {
                                                            toast(
                                                                <NotificationToast
                                                                    title={"Application Successful. Redirecting to Edit form"}
                                                                    message="Your application has succesfully been created. Keep an eye out on your email and cellphone for future communication"
                                                                />
                                                            );
                                                            setTimeout(() => {
                                                                window.location = window.location.origin + "/edit_application/" + response.data;
                                                            }, 3000);
                                                        }
                                                    })
                                                    .catch((response) => {
                                                        toast(<NotificationToast title={"Something went wrong"} message={response.response.data.message} type="error" />);
                                                    });
                                            }}
                                        >
                                            {({ values, touched, errors, setFieldValue, setFieldTouched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => (
                                                <Form onSubmit={handleSubmit}>
                                                    <main className="mt-8">
                                                        <div className="max-w-7xl mx-auto pb-2 px-4">
                                                            <div className="space-y-8 divide-y divide-gray-200">
                                                                <div className="w-full">
                                                                    <div className="xl:grid xl:grid-cols-12">
                                                                        <Tab.Group>
                                                                            <Tab.List className="pb-6 lg:pb-24 bg-light_background xl:col-span-2 rounded-3xl lg:rounded-l-3xl w-full xl:w-72 border-0">
                                                                                <div className="h-14 flex items-center rounded-t-3xl xl:rounded-tl-3xl xl:rounded-tr-none justify-center bg-dark_background">
                                                                                    <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">Sections</h1>
                                                                                </div>
                                                                                {subNavigation.map((item) => (
                                                                                    <Tab
                                                                                        key={item.name}
                                                                                        className={({ selected }) =>
                                                                                            classNames(
                                                                                                "group w-full text-sm flex items-center border-l-8 p-4 hover:cursor-pointer hover:bg-gray-200 hover:border-l-primary_hover focus:outline-none ",
                                                                                                selected ? "border-l-primary bg-gray-100 text-primary" : "bg-light_background border-l-light_background"
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        <span className="truncate">
                                                                                            <FontAwesomeIcon icon={item.icon} aria-hidden="true" className="mr-4" />
                                                                                            {item.name}
                                                                                        </span>
                                                                                    </Tab>
                                                                                ))}
                                                                                {values.joint_application == 1 &&
                                                                                    jointSubNavigation.map((item) => (
                                                                                        <Tab
                                                                                            key={item.name}
                                                                                            className={({ selected }) =>
                                                                                                classNames(
                                                                                                    "group w-full text-sm flex items-center border-l-8 p-4 hover:cursor-pointer hover:bg-gray-200 hover:border-l-primary_hover focus:outline-none ",
                                                                                                    selected
                                                                                                        ? "border-l-primary bg-gray-100 text-primary"
                                                                                                        : "bg-light_background border-l-light_background"
                                                                                                )
                                                                                            }
                                                                                        >
                                                                                            <span className="truncate">
                                                                                                <FontAwesomeIcon icon={item.icon} aria-hidden="true" className="mr-4" />
                                                                                                {item.name}
                                                                                            </span>
                                                                                        </Tab>
                                                                                    ))}
                                                                            </Tab.List>
                                                                            <Tab.Panels className="xl:ml-20 my-6 xl:my-0 rounded-3xl xl:rounded-l-none xl:rounded-r-3xl bg-gray-100 xl:col-span-9">
                                                                                <Tab.Panel className="xl:col-span-9 pb-12">
                                                                                    <div className="h-14 flex items-center rounded-t-3xl xl:rounded-tl-none xl:rounded-tr-3xl justify-center bg-dark_background">
                                                                                        <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">
                                                                                            Applicant One - Banking Details
                                                                                        </h1>
                                                                                    </div>

                                                                                    <>
                                                                                        {values.company_application ? (
                                                                                            <h2 className="pt-6 px-4 text-center leading-6 font-light text-dark_gray capitalize">
                                                                                                Please enter the banking details of your company
                                                                                            </h2>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                        <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">
                                                                                            {" "}
                                                                                            <div className="col-span-3">
                                                                                                {/* <Field name="banking_bank" type="text" as={SettingsTextInput} label="Bank" required /> */}
                                                                                                <SettingsSelect
                                                                                                    label="Bank"
                                                                                                    name="banking_bank"
                                                                                                    onChange={setFieldValue}
                                                                                                    onBlur={setFieldTouched}
                                                                                                    value={values.banking_bank}
                                                                                                    touched={touched.banking_bank}
                                                                                                    error={errors.banking_bank}
                                                                                                    options={bankList}
                                                                                                />
                                                                                                {errors.banking_bank && (
                                                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                                                        We require the <span className="font-bold italic">{errors.banking_bank}</span>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                            <div className="col-span-3">
                                                                                                <Field name="banking_account_type" type="text" as={SettingsTextInput} label="Account Type" required />
                                                                                                {errors.banking_account_type && (
                                                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                                                        We require the <span className="font-bold italic">{errors.banking_account_type}</span>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                            <div className="col-span-3">
                                                                                                <Field name="banking_branch" type="text" as={SettingsTextInput} label="Branch" required />
                                                                                                {errors.banking_branch && (
                                                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                                                        We require the <span className="font-bold italic">{errors.banking_branch}</span>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                            <div className="col-span-3">
                                                                                                <Field name="banking_branch_code" type="text" as={SettingsTextInput} label="Branch Code" required />
                                                                                                {errors.banking_branch_code && (
                                                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                                                        We require the <span className="font-bold italic">{errors.banking_branch_code}</span>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                            <div className="col-span-3">
                                                                                                <Field
                                                                                                    name="banking_account_number"
                                                                                                    type="text"
                                                                                                    as={SettingsTextInput}
                                                                                                    label="Account Number"
                                                                                                    required
                                                                                                />
                                                                                                {errors.banking_account_number && (
                                                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                                                        We require the <span className="font-bold italic">{errors.banking_account_number}</span>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                            <div className="col-span-3">
                                                                                                <Field
                                                                                                    name="banking_account_holder"
                                                                                                    type="text"
                                                                                                    as={SettingsTextInput}
                                                                                                    label="Account Holder"
                                                                                                    required
                                                                                                />
                                                                                                {errors.banking_account_holder && (
                                                                                                    <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                                                        <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                                                        We require the <span className="font-bold italic">{errors.banking_account_holder}</span>
                                                                                                    </div>
                                                                                                )}
                                                                                            </div>
                                                                                            <div className="col-span-3">
                                                                                                Please upload your bank confirmation letter so we are able to verify your bank details
                                                                                            </div>
                                                                                            <div className="col-span-3">
                                                                                                <p>Bank confirmation letter / Bank card</p>

                                                                                                <FileUpload
                                                                                                    current_booking={current_booking}
                                                                                                    setCurrentBooking={setCurrentBooking}
                                                                                                    fileUploadInformation={fileUploadInformation}
                                                                                                    file_type="BankConfirmation"
                                                                                                    url={values.edit_url}
                                                                                                />

                                                                                                {current_booking.files && current_booking.files.length > 0 && (
                                                                                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                                                                                        <ul
                                                                                                            role="list"
                                                                                                            className="border border-dark_background rounded-md divide-y divide-dark_background"
                                                                                                        >
                                                                                                            {current_booking.files.map((file) => (
                                                                                                                <>
                                                                                                                    {file.file_type === "BankConfirmation" && (
                                                                                                                        <FileDisplaySimple file={file} key={file.id} />
                                                                                                                    )}
                                                                                                                </>
                                                                                                            ))}
                                                                                                        </ul>
                                                                                                    </dd>
                                                                                                )}
                                                                                            </div>
                                                                                        </div>

                                                                                        {Object.keys(errors).length < 1 && (
                                                                                            <div className="px-8">
                                                                                                <SubmitButton
                                                                                                    name="Save"
                                                                                                    disabled={!isValid || isSubmitting || !dirty}
                                                                                                    isSubmitting={isSubmitting}
                                                                                                    text={values.edit_url ? "Finish" : "Submit Application"}
                                                                                                    style={{
                                                                                                        width: "100%",
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        )}
                                                                                    </>
                                                                                </Tab.Panel>

                                                                                {values.joint_application == 1 && (
                                                                                    <>
                                                                                        <Tab.Panel className="xl:col-span-9 pb-12">
                                                                                            <div className="h-14 flex items-center rounded-t-3xl xl:rounded-tl-none xl:rounded-tr-3xl justify-center bg-dark_background">
                                                                                                <h1 className="text-lg px-4 text-center leading-6 font-light text-white capitalize">
                                                                                                    Applicant Two - Banking Details
                                                                                                </h1>
                                                                                            </div>

                                                                                            <>
                                                                                                <div className="py-8 px-4 space-y-6 sm:space-y-0 sm:grid sm:grid-cols-6 gap-12 xl:px-6">
                                                                                                    {" "}
                                                                                                    <div className="col-span-3">
                                                                                                        <SettingsSelect
                                                                                                            label="Bank"
                                                                                                            name="joint_application_banking_bank"
                                                                                                            onChange={setFieldValue}
                                                                                                            onBlur={setFieldTouched}
                                                                                                            value={values.joint_application_banking_bank}
                                                                                                            touched={touched.joint_application_banking_bank}
                                                                                                            error={errors.joint_application_banking_bank}
                                                                                                            options={bankList}
                                                                                                        />
                                                                                                        {errors.joint_application_banking_bank && (
                                                                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                                                                We require the{" "}
                                                                                                                <span className="font-bold italic">{errors.joint_application_banking_bank}</span>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="col-span-3">
                                                                                                        <Field
                                                                                                            name="joint_application_banking_account_type"
                                                                                                            type="text"
                                                                                                            as={SettingsTextInput}
                                                                                                            label="Account Type"
                                                                                                        />
                                                                                                        {errors.joint_application_banking_account_type && (
                                                                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                                                                We require the{" "}
                                                                                                                <span className="font-bold italic">
                                                                                                                    {errors.joint_application_banking_account_type}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="col-span-3">
                                                                                                        <Field
                                                                                                            name="joint_application_banking_branch"
                                                                                                            type="text"
                                                                                                            as={SettingsTextInput}
                                                                                                            label="Branch"
                                                                                                        />
                                                                                                        {errors.joint_application_banking_branch && (
                                                                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                                                                We require the{" "}
                                                                                                                <span className="font-bold italic">{errors.joint_application_banking_branch}</span>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="col-span-3">
                                                                                                        <Field
                                                                                                            name="joint_application_banking_branch_code"
                                                                                                            type="text"
                                                                                                            as={SettingsTextInput}
                                                                                                            label="Branch Code"
                                                                                                        />
                                                                                                        {errors.joint_application_banking_branch_code && (
                                                                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                                                                We require the{" "}
                                                                                                                <span className="font-bold italic">{errors.joint_application_banking_branch_code}</span>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="col-span-3">
                                                                                                        <Field
                                                                                                            name="joint_application_banking_account_number"
                                                                                                            type="text"
                                                                                                            as={SettingsTextInput}
                                                                                                            label="Account Number"
                                                                                                        />
                                                                                                        {errors.joint_application_banking_account_number && (
                                                                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                                                                We require the{" "}
                                                                                                                <span className="font-bold italic">
                                                                                                                    {errors.joint_application_banking_account_number}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                    <div className="col-span-3">
                                                                                                        <Field
                                                                                                            name="joint_application_banking_account_holder"
                                                                                                            type="text"
                                                                                                            as={SettingsTextInput}
                                                                                                            label="Account Holder"
                                                                                                        />
                                                                                                        {errors.joint_application_banking_account_holder && (
                                                                                                            <div className="text-orange-500 col-span-2 text-sm font-light">
                                                                                                                <FontAwesomeIcon className=" mr-1" icon={faTriangleExclamation} />
                                                                                                                We require the{" "}
                                                                                                                <span className="font-bold italic">
                                                                                                                    {errors.joint_application_banking_account_holder}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>
                                                                                            </>

                                                                                            {Object.keys(errors).length < 1 && (
                                                                                                <div className="px-8">
                                                                                                    <SubmitButton
                                                                                                        name="Save"
                                                                                                        disabled={!isValid || isSubmitting || !dirty}
                                                                                                        isSubmitting={isSubmitting}
                                                                                                        text={values.edit_url ? "Finish" : "Submit Application"}
                                                                                                        style={{
                                                                                                            width: "100%",
                                                                                                        }}
                                                                                                    />
                                                                                                </div>
                                                                                            )}
                                                                                        </Tab.Panel>
                                                                                    </>
                                                                                )}
                                                                            </Tab.Panels>
                                                                        </Tab.Group>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </main>
                                                </Form>
                                            )}
                                        </Formik>
                                    </>
                                ) : (
                                    <>
                                        <div className="mx-auto bg-white max-w-7xl">
                                            <main className="mt-14">
                                                <div className="max-w-7xl mx-auto pb-2 px-4">
                                                    <div className="w-full">
                                                        {/* <p>Please fill in the Application Form first</p> */}
                                                        <p>You are missing some information on your application.</p>
                                                        <a
                                                            href={"/edit_application/" + current_booking?.edit_link !== null ? current_booking?.edit_link?.edit_url : current_booking.user.edit_url}
                                                            className="mt-2 relative justify-center inline-flex text-white uppercase items-center px-8 py-2 rounded-full border text-sm focus:z-10 focus:outline-none border-primary bg-primary hover:bg-primary_hover focus:ring-primary focus:ring-2 focus:ring-offset-2 transition duration-150 ease-in-ease-out hover:-translate-y-0.5 shadow-md font-bold"
                                                        >
                                                            CLICK HERE TO EDIT YOUR PROFILE
                                                        </a>
                                                    </div>
                                                </div>
                                            </main>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </main>
        </div>
    );
}
