import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import FileUpload from "../Forms/FileUpload";
import FileDisplaySimple from "../Forms/FileDisplaySimple";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export default function QLDocumentUploadComp() {
    const dispatch = useDispatch();
    const { url } = useParams();
    const [loading, setLoading] = useState(false);
    const [fileUploadInformation, setFileUploadInformation] = useState([]);
    const [current_booking, setCurrentBooking] = useState({});

    useEffect(async () => {
        setLoading(true);

        if (url) {
            axios({
                method: "get",
                url: `${process.env.REACT_APP_API_URL}booking_url/${url}`,
            })
                .then((response) => {
                    setCurrentBooking(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setLoading(false);
        }
    }, []);

    if (!current_booking.id) {
        return <p>loading</p>;
    }
    return (
        <div className="xl:col-span-9 pb-12">
            <div>
                <p className="text-center my-4">We require the following files from you:</p>
                <div className="flex flex-wrap justify-around space-x-2 space-y-2">
                    <div className="w-1/3">
                        <p>Copy of your ID</p>
                        <FileUpload current_booking={current_booking} setCurrentBooking={setCurrentBooking} fileUploadInformation={fileUploadInformation} file_type="ID" url={url} />

                        {current_booking.files && current_booking.files.length > 0 && (
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <ul role="list" className="border border-dark_background rounded-md divide-y divide-dark_background">
                                    {current_booking.files.map((file) => (
                                        <>{file.file_type === "ID" && <FileDisplaySimple file={file} key={file.id} />}</>
                                    ))}
                                </ul>
                            </dd>
                        )}
                    </div>

                    <div className="w-1/3">
                        <p>Copy of your Proof of Address</p>

                        <FileUpload current_booking={current_booking} setCurrentBooking={setCurrentBooking} fileUploadInformation={fileUploadInformation} file_type="Address" url={url} />

                        {current_booking.files && current_booking.files.length > 0 && (
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <ul role="list" className="border border-dark_background rounded-md divide-y divide-dark_background">
                                    {current_booking.files.map((file) => (
                                        <>{file.file_type === "Address" && <FileDisplaySimple file={file} key={file.id} />}</>
                                    ))}
                                </ul>
                            </dd>
                        )}
                    </div>

                    <div className="w-1/3">
                        <p>Copy of your 3 Month Payslips</p>

                        <FileUpload current_booking={current_booking} setCurrentBooking={setCurrentBooking} fileUploadInformation={fileUploadInformation} file_type="Payslip" url={url} />

                        {current_booking.files && current_booking.files.length > 0 && (
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <ul role="list" className="border border-dark_background rounded-md divide-y divide-dark_background">
                                    {current_booking.files.map((file) => (
                                        <>{file.file_type === "Payslip" && <FileDisplaySimple file={file} key={file.id} />}</>
                                    ))}
                                </ul>
                            </dd>
                        )}
                    </div>

                    <div className="w-1/3">
                        <p>Copy of your 3 Month Bank Statements</p>

                        <FileUpload current_booking={current_booking} setCurrentBooking={setCurrentBooking} fileUploadInformation={fileUploadInformation} file_type="BankStatement" url={url} />

                        {current_booking.files && current_booking.files.length > 0 && (
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <ul role="list" className="border border-dark_background rounded-md divide-y divide-dark_background">
                                    {current_booking.files.map((file) => (
                                        <>{file.file_type === "BankStatement" && <FileDisplaySimple file={file} key={file.id} />}</>
                                    ))}
                                </ul>
                            </dd>
                        )}
                    </div>

                    <div className="w-1/3">
                        <p>Bank confirmation letter / Bank card</p>

                        <FileUpload current_booking={current_booking} setCurrentBooking={setCurrentBooking} fileUploadInformation={fileUploadInformation} file_type="BankConfirmation" url={url} />

                        {current_booking.files && current_booking.files.length > 0 && (
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <ul role="list" className="border border-dark_background rounded-md divide-y divide-dark_background">
                                    {current_booking.files.map((file) => (
                                        <>{file.file_type === "BankConfirmation" && <FileDisplaySimple file={file} key={file.id} />}</>
                                    ))}
                                </ul>
                            </dd>
                        )}
                    </div>

                    <div className="w-1/3"></div>
                </div>
            </div>
        </div>
    );
}
