import { useState } from "react";
import CustomButton from "../Helpers/CustomButton";

export default function SettingsCounter({ setFieldValue, ...props }) {
    const [count, setCount] = useState(props.value ? props.value : 0);

    function cntup(type) {
        if (type == "+") {
            setCount(count + 1);
            setFieldValue(props.name, count + 1);
        } else {
            setCount(count - 1);
            setFieldValue(props.name, count - 1);
        }
    }

    return (
        <div className="flex items-center justify-between col-span-6 sm:col-span-3">
            <div>
                <label htmlFor={props.name} className="block text-sm font-medium mb-2 text-gray-700">
                    {props.label}
                </label>
                <div className="flex items-center space-x-2">
                    <CustomButton styling="px-2 py-0" text="-" type="button" onClick={() => cntup("-")} />
                    <span>{props.value}</span>
                    <CustomButton styling="px-2 py-0" text="+" type="button" onClick={() => cntup("+")} />
                </div>
            </div>
        </div>
    );
}
