import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";

// import { faBuildingCircleCheck, faUser, faBriefcase, faCreditCard, faHouse, faPeople, faBank, faUserGroup } from "@fortawesome/pro-regular-svg-icons";
// import FileUpload from "../Forms/FileUpload";
// import FileDisplaySimple from "../Forms/FileDisplaySimple";
// import { getBooking } from "../../store/booking/bookingActions";
// import CustomButton from "../Helpers/CustomButton";
import QLDocumentUploadComp from "./QLDocumentUploadComp";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
export default function DocumentsQL() {
    const dispatch = useDispatch();
    const { url } = useParams();
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [properties, setProperties] = useState([]);
    const [current_booking, setCurrentBooking] = useState({});

    return (
        <div className="pb-32 elative bg-fixed bg-bottom bg-cover bg-no-repeat application_form">
            <header className="max-w-7xl mx-auto py-10 px-2 lg:px-0">
                <div className="lg:flex">
                    <img className="h-24" src="/images/logo.svg" alt="Quorum Living" />
                    <div className="py-6 lg:py-0 mx-auto md:w-96 text-center">
                        <h1 className="text-3xl text-white">
                            Welcome {url && "back"} to QLiving {url && current_booking && current_booking.user && current_booking.user.name + " " + current_booking.user.surname}
                        </h1>
                    </div>
                </div>
            </header>
            <main className="mt-24">
                <div className="max-w-7xl mx-auto pb-2 px-4">
                    <div className="space-y-8 divide-y divide-gray-200">
                        <div className="w-full">
                            <div className="xl:grid xl:grid-cols-12">
                                <div className="xl:ml-20 my-6 xl:my-0 xl:rounded-3xl rounded-3xl bg-gray-100 xl:col-span-9 ">
                                    <div className="xl:col-span-9 pb-12">
                                        <QLDocumentUploadComp url={url} current_booking={current_booking} setCurrentBooking={setCurrentBooking} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
}
